import React from "react"
import { Link } from "gatsby"
import aboutImage from "../../assets/images/about/about-img5.png"
import starIcon from "../../assets/images/star-icon.png"
import icon4 from "../../assets/images/icons/icon4.png"
import icon5 from "../../assets/images/icons/icon5.png"
import icon6 from "../../assets/images/icons/icon6.png"
import icon7 from "../../assets/images/icons/icon7.png"
import shape1 from "../../assets/images/shape/circle-shape1.png"

const AboutUsContent = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={aboutImage} alt="banner" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={starIcon} alt="banner" />
                  ABOUT iHunar
                </span>
                <h2>Excellent IT services for your success</h2>
                <p>
                  We Are Increasing Business Success With Technology. Over 1
                  year working in IT services developing software applications,
                  websites and mobile apps for clients all over the World.
                </p>

                {/* <ul className="features-list">
                  <li>
                    <img src={icon4} alt="banner" />
                    <h3>10 Years</h3>
                    <p>On the market</p>
                  </li>
                  <li>
                    <img src={icon5} alt="banner" />
                    <h3>45+</h3>
                    <p>Team members</p>
                  </li>
                  <li>
                    <img src={icon6} alt="banner" />
                    <h3>100%</h3>
                    <p>Satisfaction rate</p>
                  </li>
                  <li>
                    <img src={icon7} alt="banner" />
                    <h3>80%</h3>
                    <p>Senior scientist</p>
                  </li>
                </ul> */}

                {/* <Link to="/about-us" className="default-btn">
                  <i className="flaticon-right"></i>More About Us<span></span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our Mission</h3>
                <p>To cater to every Software need, Swiftly and Effectively</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our Vision</h3>
                <p>
                  To be the best software solution provider in the world,
                  helping change the world of Technology through our top-notch
                  Software Engineering Solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>
    </section>
  )
}

export default AboutUsContent
